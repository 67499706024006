import GlobalBannerWrapper from '@/baseline/navigation/desktop/banners/wrapper';
import SubscriptionModal from '@/baseline/subscription';
import { getSubscription } from '@/baseline/subscription/getSubscription';
import { useCompany } from '@/hooks/useSetCompanyInAtom';
import { useModal } from '@/providers/modal';
import { dismissibleBannerAtoms } from '@/utils/atoms';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { differenceInCalendarDays } from 'date-fns';
import { useAtom } from 'jotai/index';

export default function SubscriptionBanner() {
	const [ globalBanners, setGlobalBanners ] = useAtom( dismissibleBannerAtoms );
	const { company } = useCompany();
	const { showModal } = useModal();
	const subscription = getSubscription( { subscriptions: company?.subscriptions } );
	
	return (
		<GlobalBannerWrapper
			color='alpha.amethyst'
			show={!globalBanners.hideSubscriptionBanner && subscription?.tier.name === 'Trial'}
			onClose={() => setGlobalBanners( ( prev ) => ( { ...prev, hideSubscriptionBanner: true } ) )}>
			<Stack
				direction={{ xs: 'column', lg: 'row' }}
				alignItems='center'
				spacing={2}
				justifyContent='center'>
				<div/>
				<Typography variant='h5' sx={{ textAlign: 'center', fontWeight: 'normal', color: 'amethyst.main' }}>
					You are currently subscribed to the {subscription?.tier.name} plan. Your trial for some features will
					expire in
					<Chip
						label={(
							<Typography>
								{differenceInCalendarDays( new Date( subscription?.endDate ), new Date() )} Days
							</Typography>
						)}
						variant='alpha'
						color='warning'
						sx={{ mx: .5 }}
					/> Please refer to our plan page for more details.
				</Typography>
				<Button
					variant='text'
					color='inverted'
					onClick={() => showModal( SubscriptionModal, { variant: 'fullPageDialog' } )}>
					View plans
				</Button>
			</Stack>
		</GlobalBannerWrapper>
	);
}

