import AuthButtons from '@/baseline/navigation/desktop/authButtons';
import HelpButton from '@/baseline/navigation/desktop/helpButton';
import ProfileButton from '@/baseline/navigation/desktop/profileButton';
import ElevationScroll from '@/components/elevationScroll';
import Loading from '@/components/loading';
import ErrorPage from '@/pages/_error.page';
import AddressButton from '@/pages/p/store/addressButton';
import CartButton from '@/pages/p/store/cart/button';
import { useStorePublicRead } from '@/pages/p/store/context';
import MyOrdersButton from '@/pages/p/store/myOrdersButton';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Box, Container, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { Fragment, ReactNode } from 'react';

export default function PublicStoreLayout( { children }: { children: ReactNode } ) {
	const { user } = useUserInfo();
	
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	
	const { store, isLoading, isError } = useStorePublicRead();
	
	const { data: storePrivacy, isFetching: privacyIsLoading } = useQuery<any>(
		[ 'storePrivacy' ],
		async () => await axios.post( `/api/checkStorePrivacy`, {
			companyId       : store?.company.id,
			email           : user?.email,
			storeCategoryIds: store?.clientCategories?.map( ( c ) => c?.id ),
		} ), { enabled: Boolean( user ) && Boolean( store ) } );
	
	const isStoreSignInRequired = store?.metadata?.signInRequired;
	const isStoreValidForThisUser = storePrivacy?.data?.storeIsPrivate;
	
	if ( isLoading || privacyIsLoading ) return <Loading/>;
	if ( isError || !store ) return (
		<ErrorPage
			title='There was an error fetching the store'
			subtitle='Please try again later'
		/>
	);
	const storeIsNotValid = isStoreSignInRequired && ( !user || Boolean( store?.clientCategories?.length ) && isStoreValidForThisUser );
	
	return (
		<Fragment>
			<ElevationScroll>
				<Container maxWidth='xl' sx={{ py: 1 }}>
					<Stack
						direction='row'
						alignItems='center'
						spacing={2}>
						{!storeIsNotValid && <AddressButton/>}
						<Box display='flex' flexGrow={1}/>
						<Stack
							direction='row'
							alignItems='center'
							spacing={2}>
							{!storeIsNotValid && <CartButton/>}
							{user ? (
								<Fragment>
									{!isStoreSignInRequired && <MyOrdersButton/>}
									{!isMobile && <HelpButton/>}
									<ProfileButton/>
								</Fragment>
							) : (
								<AuthButtons/>
							)}
						</Stack>
					</Stack>
				</Container>
			</ElevationScroll>
			{storeIsNotValid ? (
				<Stack spacing={2} sx={{ p: 4, textAlign: 'center' }}>
					<Typography variant='h2'>
						Sorry, this store is private
					</Typography>
					{user?.email && (
						<Typography variant='h5'>
							You're signed in with {user?.email}
						</Typography>
					)}
					<Typography color='tex.secondary'>
						Please make sure to sign in with a valid account or ask the merchant to grant you access
					</Typography>
				</Stack>
			) : (
				<Fragment>
					{children}
				</Fragment>
			)}
		</Fragment>
	);
}
