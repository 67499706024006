import EmergencyBannerIframe from '@/baseline/navigation/desktop/emergencyBannerIframe';
import { auth } from '@/firebase/client';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import { isProduction } from '@/utils/config';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { GoogleAnalytics, GoogleTagManager } from '@next/third-parties/google';
import { useSetAtom } from 'jotai';
import { atom } from 'jotai/index';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { Fragment, useEffect, useState } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import packageJSON from '../../package.json';
import Baseline from '../baseline';
import '../baseline/styles.scss';
import '../i18n';

if ( typeof window !== 'undefined' ) defineCustomElements( window ).then();

export const companyModeIdAtom = atom( '' );

// noinspection JSUnusedGlobalSymbols
export default function App( { Component, pageProps, router }: AppProps ) {
	const setCompanyModeId = useSetAtom( companyModeIdAtom );
	const { query, isReady } = useRouter();
	const [ loadWidget, setLoadWidget ] = useState( false );
	const { isCloverDevice } = useGetDeviceInfo();
	
	useEffect( () => {
		if ( process.env.NODE_ENV === 'development' ) return;
		setTimeout( () => {
			if ( auth.currentUser ) {
				window.CobrowseIO.customData = {
					user_id   : auth.currentUser.uid,
					user_name : auth.currentUser.displayName,
					user_email: auth.currentUser.email,
				};
			}
			window.CobrowseIO?.client().then( () => window.CobrowseIO.start() );
		}, 10000 );
	}, [] );
	
	useEffect( () => {
		if ( !isReady ) return;
		setLoadWidget( !isProduction && query?.noWidget === undefined );
		if ( query?.companyId ) {
			setCompanyModeId( query.companyId as string );
		}
	}, [ isReady ] );
	
	useEffect( () => {
		if ( !process.env.NEXT_PUBLIC_SOCKET_URL ) {
			console.error( 'NEXT_PUBLIC_SOCKET_URL not defined' );
			return;
		}
		( window as any ).rws = new ReconnectingWebSocket( process.env.NEXT_PUBLIC_SOCKET_URL );
		return () => {
			( window as any ).rws?.close();
		};
	}, [] );
	
	useEffect( () => {
		if ( isProduction ) {
			datadogLogs.init( {
				clientToken        : process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN!,
				site               : process.env.NEXT_PUBLIC_DATADOG_SITE,
				forwardErrorsToLogs: true,
				sessionSampleRate  : 100,
				beforeSend         : ( log ) => {
					// from a bot:
					if ( log.message?.includes( 'injectScript error:' ) ) return false;
					if ( log.message?.includes( 'Object Not Found Matching Id' ) ) return false;
					
					if ( log.message?.includes( 'Uncaught "Script error.' ) ) return false;
					if ( log.message?.includes( 'Loading chunk' ) ) return false;
					if ( log.message?.includes( 'Error: Failed to load script: /_next/static/chunks' ) ) return false;
					if ( log.message?.includes( 'Fetch error GET https://www.invoiss.com/_next' ) ) return false;
					if ( log.message?.includes( 'Fetch error POST https://api.invoiss.com/graphql?' ) ) return false;
					
					// from google api:
					if ( log.message?.includes( 'Could not load "util".' ) ) return false;
					if ( log.message?.includes( 'XHR error GET https://maps.googleapis.com' ) ) return false;
					if ( log.message?.includes( 'Fetch error POST https://www.google-analytics.com' ) ) return false;
					if ( log.message?.includes( 'There has been an Error with loading Google Maps API script' ) ) return false;
					
					if ( log.message?.includes( 'Connection to Indexed Database server lost' ) ) return false;
					if ( log.message?.includes( 'Fetch error POST https://vitals.vercel-insights.com/v1/vitals' ) ) return false;
					return true;
				},
			} );
			datadogRum.init( {
				applicationId          : process.env.NEXT_PUBLIC_DATADOG_RUM_APP_ID!,
				clientToken            : process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN!,
				site                   : process.env.NEXT_PUBLIC_DATADOG_SITE,
				service                : 'invoiss',
				env                    : 'production',
				version                : packageJSON.version,
				sessionSampleRate      : 100,
				sessionReplaySampleRate: 100,
				trackUserInteractions  : true,
				trackResources         : true,
				trackLongTasks         : true,
				defaultPrivacyLevel    : 'mask-user-input',
				
			} );
		}
	}, [] );
	
	return (
		<Fragment>
			{process.env.NODE_ENV === 'production' && (
				<Fragment>
					<GoogleAnalytics gaId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID!}/>
					<GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID!}/>
					{/*{!isCloverDevice && <Analytics/>}*/}
				</Fragment>
			)}
			<Head>
				<meta
					name='viewport'
					content='width=device-width,
						minimum-scale=1,
						maximum-scale=1,
						initial-scale=1,
						user-scalable=no
						viewport-fit=cover'
				/>
			</Head>
			<EmergencyBannerIframe/>
			<Baseline pageProps={pageProps}>
				<Component {...pageProps} key={router.route}/>
			</Baseline>
			<div id='recaptcha-container'/>
			{loadWidget && (
				<Script id='widget-config'>
					{`window.invoissConfig = {
					        storeUrl: "https://dev.invoiss.com/p/43a8b054-79e8-4353-9e40-ae9dcc459029/store?noWidget=true",
					        appId: "u4himQhRWJiXXxqP35dZ",
					    }`}
				</Script>
			)}
		</Fragment>
	);
}
