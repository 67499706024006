import { urlSearchParams } from '@/utils/urlSearchParams';
import { useAtom } from 'jotai';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { axiosClient } from '../data';
import { auth } from '../firebase/client';
import { unreadMessagesCountAtom, userAtom } from '../utils/atoms';
import { getSessionCompanyId } from '../utils/getCompanyId';
import { useEvents } from './event';

export default function ChatProvider( { children } ) {
	const event = useEvents();
	const { enqueueSnackbar } = useSnackbar();
	const [ user ] = useAtom( userAtom );
	const [ , setUnreadMessagesCount ] = useAtom( unreadMessagesCountAtom );
	
	useEffect( () => {
		const companyId = getSessionCompanyId();
		const staff = user?.staffs?.find( ( { company } ) => company?.id === companyId );
		const clientView = Boolean( user ) && !Boolean( staff );
		const shouldSubscribe = clientView || staff && ( staff.company.chatSettings.external || staff.company.chatSettings.internal );
		
		if ( !shouldSubscribe || !auth.currentUser ) return;
		
		const topic = `CHAT-${auth.currentUser.uid}`;
		const win = window as any;
		
		const handleChatEvent = async ( args ) => {
			if ( args.roomId ) {
				if ( clientView || staff?.chat?.push === true || staff?.chat?.push === null ) {
					enqueueSnackbar( `${args.from}: ${args.content}`, { variant: 'info' } );
				}
				if ( clientView || staff?.chat?.sound === true || staff?.chat?.sound === null ) {
					await new Audio( '/sounds/pop-sound.mp3' ).play();
				}
			}
			const { data } = await axiosClient.post(
				`/api/user/chat/countUnreads?${urlSearchParams( {
					uid      : auth.currentUser?.uid,
					companyId: clientView ? null : staff?.company?.id,
				} )}`,
			);
			setUnreadMessagesCount( data.unreadMessages );
		};
		
		win.rws.send( `SUBSCRIBE:${topic}` );
		event.on( topic, handleChatEvent );
		
		return () => {
			win.rws.send( `UNSUBSCRIBE:${topic}` );
			event.off( topic, handleChatEvent );
		};
	}, [ user ] );
	
	return children;
	
}
