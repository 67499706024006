import { menuDarkModeBoxShadow, popOverShadow } from '@/providers/theme/themes';
import { GlobalStyles, useTheme } from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

export default function AppStyles() {
	const theme = useTheme();
	const isDarkMode = theme.palette.mode === 'dark';
	const PaperElevatedBoxShadow = !isDarkMode
		? theme.shadows[ popOverShadow as any ]
		: menuDarkModeBoxShadow;
	
	useEffect( () => {
		AOS.init( { duration: 2000, once: false, mirror: true, debounceDelay: 50 } );
		AOS.refresh();
	}, [] );
	
	return (
		<GlobalStyles
			styles={{
				// =============================================
				//             			Body
				// =============================================
				'html': {},
				'body': {
					backgroundColor: theme.palette.background.default,
					overflow       : 'scroll',
					// paddingLeft    : 'calc(100vw - 100%)',
					overflowX      : 'hidden',
				},
				// =============================================
				//             		ScrollBars
				// =============================================
				'input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill': {
					WebkitTransitionDelay: '99999s',
					WebkitTextFillColor  : `${theme.palette.text.primary} !important`,
					WebkitBoxShadow      : 'unset !important',
				},
				'::-webkit-scrollbar': {
					width          : '0.7em',
					height         : '0.7em',
					// @ts-ignore
					backgroundColor: theme.palette.colors.info.light,
				},
				'::-webkit-scrollbar-track': {
					boxShadow      : 'inset 0 0 6px rgba(0,0,0,0.00)',
					// webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
					backgroundColor: 'transparent',
				},
				'::-webkit-scrollbar-thumb': {
					// @ts-ignore
					backgroundColor: theme.palette.colors.info.hover,
					borderRadius   : 10,
				},
				'::-webkit-scrollbar-corner': {
					backgroundColor: 'transparent',
				},
				// =============================================
				//         		notistack
				// =============================================
				'.SnackbarContent-root': {
					borderRadius: '10px !important',
				},
				// =============================================
				//         		Remove Clover Footer
				// =============================================
				'.clover-footer': {
					display: 'none !important',
				},
				// =============================================
				//         		Remove rc-anchor-alert
				// =============================================
				'.grecaptcha-badge': {
					display: 'none !important',
				},
				// =============================================
				//         		Google Places DropDrown
				// =============================================
				'.pac-container': {
					backgroundColor: `${theme.palette.background.default} !important`,
					boxShadow      : PaperElevatedBoxShadow,
					marginTop      : 5,
					paddingRight   : 2,
					paddingLeft    : 2,
					border         : 0,
					borderRadius   : 7,
					zIndex         : 1500,
					// '::after'        : { display: 'none' },
				},
				'.pac-item': {
					color        : `${theme.palette.text.secondary} !important`,
					borderTop    : '0 !important',
					margin       : 5,
					paddingTop   : 5,
					paddingBottom: 5,
					fontSize     : 13,
				},
				'.pac-item-query': {
					color   : `${theme.palette.text.primary} !important`,
					fontSize: 14,
				},
				'.pac-item:hover': {
					backgroundColor: `${theme.palette.divider} !important`,
					borderRadius   : 7,
				},
				// =============================================
				//         			Ripple Effect
				// =============================================
				'@keyframes ripple': {
					'0%': {
						transform: 'scale(.1)',
						opacity  : 1,
					},
					'100%': {
						transform: 'scale(1)',
						opacity  : 0,
					},
				},
				'@-webkit-keyframes ripple2': {
					'0%': {
						boxShadow: '0 4px 10px #ff418219, 0 0 0 0 #ff4184, 0 0 0 5px #ff418219, 0 0 0 10px #ff418219',
					},
					'100%': {
						boxShadow: '0 4px 10px #ff418219, 0 0 0 5px #ff418219, 0 0 0 10px #ff418219, 0 0 0 20px rgba(255, 65, 130, 0)',
					},
				},
				'@keyframes ripple2': {
					'0%': {
						boxShadow: '0 4px 10px #ff418219, 0 0 0 0 #ff4184, 0 0 0 5px #ff418219, 0 0 0 10px #ff418219',
					},
					'100%': {
						boxShadow: '0 4px 10px #ff418219, 0 0 0 5px #ff418219, 0 0 0 10px #ff418219, 0 0 0 20px rgba(255, 65, 130, 0)',
					},
				},
				'.ripple-dot': {
					position       : 'relative',
					backgroundColor: '#e31a56',
					borderRadius   : '100%',
					width          : '10px',
					height         : '10px',
					display        : 'flex',
					justifyContent : 'center',
					alignItems     : 'center',
				},
				'.ripple-effect': {
					content        : '""',
					position       : 'absolute',
					zIndex         : 2,
					left           : '-100%',
					top            : '-100%',
					animation      : 'ripple 2s infinite ease-in-out',
					width          : '30px',
					height         : '30px',
					backgroundColor: '#e31a56',
					border         : '1px solid #e31a56',
					borderRadius   : '100%',
				},
				// =============================================
				//         		 Moving Gradient
				// =============================================
				'@keyframes animatedGradient': {
					'0%': {
						backgroundPosition: '0% 50%',
					},
					'50%': {
						backgroundPosition: '100% 50%',
					},
					'100%': {
						backgroundPosition: '0% 50%',
					},
				},
				'.gradient': {
					background    : 'linear-gradient(-45deg, #c7fef1,#f9c3b5,#bbedff,#f5c2d5)',
					backgroundSize: '400% 400%',
					animation     : 'animatedGradient 15s ease infinite',
				},
				// =============================================
				//         		 Moving Gradient Static Site
				// =============================================
				'.linearTextColor1': {
					background: 'linear-gradient(90deg, #ffffff,#a3a3a3,#a3a3a3,#a3a3a3,#a3a3a3,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff)',
					animation : 'animatedGradient 15s ease infinite',
				},
				'.linearTextColor2': {
					background: 'linear-gradient(-45deg, #9d7ee7 ,#9d7ee7,#002bff,#002bff)',
					animation : 'animatedGradient 15s ease infinite',
				},
				'.linearTextColor3': {
					background: 'linear-gradient(90deg, #ff0080,#ff0080,#7928ca,#7928ca)',
					animation : 'animatedGradient 15s ease infinite',
				},
				'.linearText': {
					textAlign           : 'center',
					backgroundSize      : '200% auto',
					color               : '#000',
					backgroundClip      : 'text',
					textFillColor       : 'transparent',
					WebkitBackgroundClip: 'text',
					WebkitTextFillColor : 'transparent',
				},
				// =============================================
				//         		Google Auth
				// =============================================
				'.firebaseui-container': {
					margin  : '0px !important',
					maxWidth: '100% !important',
				},
				'.firebaseui-card-content': {
					padding: '0px 15px !important',
				},
				'& .firebaseui-idp-button': {
					borderRadius  : '8px !important',
					minWidth      : '100% !important',
					display       : 'flex',
					alignItems    : 'center',
					justifyContent: 'center',
				},
				'.mdl-button': {
					display: 'flex !important',
				},
				'.firebaseui-card-footer': {
					margin  : '0px auto !important',
					maxWidth: '360px !important',
				},
				// =============================================
				//         				 Custom
				// =============================================
				'.center': {
					height        : '100%',
					display       : 'flex',
					alignItems    : 'center',
					justifyContent: 'center',
				},
				// 'a': {
				// 	'color'         : theme.palette.primary.main,
				// 	'textDecoration': 'none !important',
				// 	'borderBottom'  : '1px solid transparent',
				// 	'transition'    : 'all ease-in-out .2s',
				// 	':hover'        : {
				// 		borderColor: theme.palette.primary.main,
				// 	},
				// },
				'.bordered': {
					border      : `1px solid ${theme.palette.divider}`,
					borderRadius: 7,
				},
				'.commerceAccordion': {
					'width'             : '100%',
					'.MuiAccordion-root': {
						'borderRadius': '10px !important',
						':before'     : { display: 'none' },
					},
					'.MuiPaper-root': {
						border: 'none !important',
					},
				},
				'.headerFontSizeSmall': {
					fontSize  : '16px !important',
					fontWeight: '500 !important',
				},
				'.headerFontSizeLarge': {
					fontSize  : '55px !important',
					fontWeight: 'bold !important',
					lineHeight: '1.2 !important',
				},
				'.headerFontSizeMedium': {
					fontSize: '28px !important',
				},
				'.sectionTitle': {
					fontSize  : '20px !important',
					fontWeight: '500 !important',
					lineHeight: '1 !important',
				},
				'.sectionHeaderLarge': {
					fontSize  : '40px !important',
					fontWeight: '500 !important',
					lineHeight: '1.2 !important',
				},
				'.sectionHeaderSubText': {
					fontSize: '20px !important',
				},
				'.cardTitle': {
					fontSize  : '25px !important',
					fontWeight: '500 !important',
					lineHeight: '1.2 !important',
				},
				'.cardParagraph': {
					fontSize: '16px !important',
				},
				'.ellipsisText': {
					overflow       : 'hidden',
					textOverflow   : 'ellipsis',
					display        : '-webkit-box',
					WebkitLineClamp: 3,
					WebkitBoxOrient: 'vertical',
					whiteSpace     : 'unset',
				},
				'.ellipsisText5Line': {
					overflow       : 'hidden',
					textOverflow   : 'ellipsis',
					display        : '-webkit-box',
					WebkitLineClamp: 5,
					WebkitBoxOrient: 'vertical',
					whiteSpace     : 'unset',
				},
				
			}}
		/>
	);
}
