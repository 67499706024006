import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import PageLinkComponent from '@/components/page/linkComponent';
import { useModal, useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import {
	AdminPanelSettingsTwoTone as AdminPanelSettingsTwoToneIcon,
	ContentCopy as ContentCopyIcon,
	Email as EmailIcon,
	Lock as LockIcon,
	PeopleTwoTone,
} from '@mui/icons-material';
import { Button, IconButton, Stack, TextField, Tooltip } from '@mui/material';
import axios from 'axios';
import { isEmpty } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { Fragment, useState } from 'react';

export default function AdminButton() {
	return (
		<Fragment>
			<Button
				color='primary'
				sx={{ borderRadius: 5 }}
				component={PageLinkComponent}
				startIcon={<AdminPanelSettingsTwoToneIcon/>}
				href='/admin/accounts'>
				Accounts
			</Button>
			<Button
				color='primary'
				sx={{ borderRadius: 5 }}
				component={PageLinkComponent}
				startIcon={<PeopleTwoTone/>}
				href='/admin/clients'>
				Clients
			</Button>
		</Fragment>
	);
}

export function VerifyEmailModal() {
	const { closeModal } = useModalControls();
	const [ email, setEmail ] = useState( '' );
	const [ link, setLink ] = useState( '' );
	const { enqueueSnackbar } = useSnackbar();
	return (
		<ResponsiveModalContainer
			title='Verify Email'
			onClose={() => {
				setEmail( '' );
				closeModal();
			}}
			onSave={closeModal}>
			<Stack direction='column' spacing={1}>
				<Stack direction='row'>
					<TextField
						fullWidth
						label='Email'
						type='email'
						value={email}
						onChange={( e ) => setEmail( e.target.value )}
					/>
					<AsyncLoadingButton
						disabled={isEmpty( email )}
						variant='contained'
						color='primary'
						size='medium'
						sx={{ mx: 1 }}
						onClick={async () => {
							try {
								const { data } = await axios.post( `/api/emails/confirmEmail`, {
									email: email,
								} );
								setLink( data?.link );
							} catch ( e ) {
								return;
							}
						}}>
						Generate Verification Link
					</AsyncLoadingButton>
				</Stack>
				{link && (
					<TextField
						fullWidth
						disabled
						value={link}
						helperText='Copy the link and send it to the client. Do NOT run the link'
						InputProps={{
							endAdornment: (
								<Stack direction='row' alignItems='center' ml={1}>
									<Tooltip title='Copy Link'>
										<IconButton
											onClick={() => {
												navigator.clipboard.writeText( link );
												enqueueSnackbar( 'Link copied to clipboard.', { variant: 'success' } );
											}}>
											<ContentCopyIcon fontSize='small'/>
										</IconButton>
									</Tooltip>
								</Stack>
							),
						}}
					/>
				)}
			</Stack>
		</ResponsiveModalContainer>
	);
}

export function VerifyEmailButton() {
	const { showModal } = useModal();
	return (
		<Button
			color='primary'
			sx={{ borderRadius: 5 }}
			startIcon={<EmailIcon/>}
			onClick={() => showModal( VerifyEmailModal, { maxWidth: 'sm' }, undefined )}>
			Verify Email
		</Button>
	);
}

export function LogOutButton() {
	return (
		<Button
			color='primary'
			sx={{ borderRadius: 5 }}
			component={PageLinkComponent}
			startIcon={<LockIcon/>}
			href='/logout'>
			Log Out
		</Button>
	);
}
