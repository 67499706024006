import {
	ContactsRounded as ContactsRoundedIcon,
	GridViewOutlined as GridViewOutlinedIcon,
	Group as GroupIcon,
} from '@mui/icons-material';

export default function useGetPartnerDashboardTabs() {
	return [ {
		name  : 'Dashboard',
		Icon  : GridViewOutlinedIcon,
		detail: 'Dashboard',
		href  : '/partner/dashboard',
	}, {
		name  : 'Accounts',
		Icon  : ContactsRoundedIcon,
		detail: 'View and manage accounts',
		href  : '/partner/dashboard/accounts',
	}, /*{
		name  : 'Residual',
		Icon  : PaymentsRoundedIcon,
		detail: 'View and manage residual',
		href  : '/partner/dashboard/residual',
	}, {
		name  : 'Reports',
		Icon  : ShowChartIcon,
		detail: 'View and manage reports',
		href  : '/partner/dashboard/reports',
	}, {
		name  : 'Tickets',
		Icon  : ConfirmationNumberRoundedIcon,
		detail: 'View and manage tickets',
		href  : '/partner/dashboard/tickets',
	}, {
		name  : 'CRM',
		Icon  : ManageAccountsRoundedIcon,
		detail: 'View and manage CRM',
		href  : '/partner/dashboard/crm',
	},  {
		name  : 'Fee Calculator',
		Icon  : GroupIcon,
		detail: 'View and manage sales agents',
		href  : '/partner/dashboard/revenueCalculator',
	}*/{
		name  : 'Sales Agents',
		Icon  : GroupIcon,
		detail: 'View and manage sales agents',
		href  : '/partner/dashboard/salesAgents',
	} ].filter( Boolean );
}

